export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const UPDATE_SURVEY_CHECKBOX = 'UPDATE_SURVEY_CHECKBOX'
export const IMPORT_FAVORITES = 'IMPORT_FAVORITES'
export const UPDATE_FAVORITES = 'UPDATE_FAVORITES'
export const FAVORITE = 'FAVORITE'
export const RESET_STATE = 'RESET_STATE'
export const TOGGLE_ORDER = 'TOGGLE_ORDER'
export const SET_ORDER = 'SET_ORDER'
export const SORT_SONGLIST = 'SORT_SONGLIST'
export const FORM_ERROR = 'FORM_ERROR'
export const FORM_SUCCESS = 'FORM_SUCCESS'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const SORT = 'SORT'
export const SEARCH = 'SEARCH'
export const BOOKMARK_VISIBLILITY = 'BOOKMARK_VISIBLILITY'
export const SET_CREDENTIALS = 'SET_CREDENTIALS'
export const FORM_SENDING = 'FORM_SENDING'

export function toggleOrder(){
  return {
    type: TOGGLE_ORDER
  }
}

export function setOrder(order){
  return {
    type: SET_ORDER,
    order: order
  }
}


export function updateSurvey(question, value) {
  return {
    type: UPDATE_SURVEY,
    question: question,
    value: value,
  }
}

export function updateSurveyCheckbox(question, checkbox, value) {
  return {
    type: UPDATE_SURVEY_CHECKBOX,
    question: question,
    checkbox: checkbox,
    value: value,
  }
}

export function favoriteWithSave(item) {
  return (dispatch, getState) => {
    dispatch(favorite(item))
    if (getState().credentials.token) {
      console.log('Logged in, attempting autosave.')
      dispatch(autoSave(getState().credentials.token))
    }
  }
}

export function favorite(item) {
  return {
    type: FAVORITE,
    item: item,
  }
}

export function saveSurvey(history)
{
  return (dispatch, getState) => {
    dispatch(formSending(true, 'survey'))
    const state = getState()
    return fetch('https://hot100.4zzz.org.au/api/sendSurvey', {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ survey: state.survey, favorites: state.favorites, order: state.order }) })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        else {
          let error = response.status
          throw error
        }
      })
      .then(json => {
        // this.setState(initialState)
        // this.props.favCount(0)
        // this.props.history.push("/hot/" + json.token);
        dispatch(resetState())
        dispatch(sortSonglist('Random', '') )
        history.push("/hot/" + json.token);
      })
      .catch(err => {
        dispatch(formError(err, 'survey'))
        if (err === 409) {
          console.log('yep its no good')
        }
        setTimeout(() => {
          dispatch(formSending(false, 'survey'))
        }, 1000)
      })
  }
}

export function saveRestore(subnumber, email, favorites)
{
  return (dispatch, getState) => {
    const state = getState()
    return fetch('https://hot100.4zzz.org.au/api/sendSub', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({subnumber: state.survey.subnumber, email: state.survey.email, favorites: state.favorites, order: state.order }) })
      .then(response => response.json())
      .then(json => {
        let favorites = json['favorites']
        favorites = favorites.sort( (a,b) => (a.pivot.position - b.pivot.position ) )
        favorites = favorites.map( (x) => ({ artist: x.artist, alpha: x.alpha, title: x.title, a: +x.a, l: +x.l, n: +x.n, i: +x.i, f: +x.f, region: x.l ? '1' : x.a && !x.l ? '2' : '3'  }) )
        dispatch(importFavorites(favorites))
        dispatch(setOrder(json['order']))
        dispatch(formSuccess(true, 'saveRestore'))
        dispatch(setCredentials(json['token']))
        setTimeout(() => {
          dispatch(clearError())
          dispatch(setBookmarkVisible(false))
        }, 500)
      })
      .catch(err => {
        console.log(err)
        dispatch(formError(err, 'saveRestore'))
        setTimeout(() => {
          dispatch(clearError())
        }, 1000)
      })
  }
}

function autoSave(token)
{
  return (dispatch, getState) => {
    const state = getState()
    return fetch('https://hot100.4zzz.org.au/api/autosave', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({token: token, favorites: state.favorites, order: state.order }) })
      .then(response => response.json())
      .then(json => {
        console.log('Autosave: success')
      })
      .catch(err => {
        console.log('Autosave: ' + err)
      })
  }
}

function formError(err, form) {
  return {
    type: FORM_ERROR,
    error: err,
    form: form
  }
}

function formSuccess(success, form) {
  return {
    type: FORM_SUCCESS,
    success: success,
    form: form
  }
}

function formSending(sending, form) {
  return {
    type: FORM_SENDING,
    sending: sending,
    form: form
  }
}



export function clearError() {
  return {
    type: CLEAR_ERROR
  }
}

function resetState(){
  return {
    type: RESET_STATE
  }
}
export function updateFavorites(favorites) {
  return {
    type: UPDATE_FAVORITES,
    favorites: favorites
  }
}

export function updateFavoritesWithSave(favorites) {
  return (dispatch, getState) => {
    dispatch(updateFavorites(favorites))
    if (getState().credentials.token) {
      console.log('Logged in, attempting autosave.')
      dispatch(autoSave(getState().credentials.token))
    }
  }
}

export function setCredentials(token) {
  return {
    type: SET_CREDENTIALS,
    token: token
  }
}

function importFavorites(favorites) {
  return {
    type: IMPORT_FAVORITES,
    favorites: favorites
  }
}

export function setSearch(search) {
  return {
    type: SEARCH,
    search: search
  }
}

export function setSort(sort) {
  return {
    type: SORT,
    sort: sort
  }
}

export function sortSonglist(sort, search) {
  return {
    type: SORT_SONGLIST,
    sort: sort,
    search: search
  }
}

export function setBookmarkVisible(visible) {
  return {
    type: BOOKMARK_VISIBLILITY,
    visible: visible
  }
}
