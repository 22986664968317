import React from 'react'

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { pink, orange } from '@material-ui/core/colors';

import Form from './Form';


const outerTheme = createMuiTheme({
  palette: {
    primary: pink,
    secondary: orange,
    background: {
      paper: '#fffbdc',
    },
  },
  overrides: {
    MuiAppBar: {
      // Name of the rule
      colorPrimary: {
        // Some CSS
        backgroundColor: orange[400],
      },
    },
    MuiOutlinedInput: {
      root: {
        // Some CSS
        backgroundColor: '#fff',
        borderRadius: 4,
      },
    }
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormDialog = props => {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <ThemeProvider theme={outerTheme}>
      <Dialog fullScreen onClose={handleClose} open={open} TransitionComponent={Transition}>
        <Form handleClose={handleClose} />
      </Dialog>
    </ThemeProvider>
  );
}

export default FormDialog
