import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './reducers'
 
const loggerMiddleware = []

if (process.env.NODE_ENV !== `production`) {
  loggerMiddleware.push(createLogger())
}

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(
      thunkMiddleware,
      ...loggerMiddleware
    )
  )
}
