import { xorWith, groupBy, flatMap, shuffle } from 'lodash/lodash';
import hot100 from './hot100-data.json';

import {
  UPDATE_SURVEY,
  UPDATE_SURVEY_CHECKBOX,
  IMPORT_FAVORITES,
  UPDATE_FAVORITES,
  FAVORITE,
  RESET_STATE,
  TOGGLE_ORDER,
  SET_ORDER,
  SORT_SONGLIST,
  FORM_ERROR,
  FORM_SUCCESS,
  FORM_SENDING,
  CLEAR_ERROR,
  SORT,
  SEARCH,
  BOOKMARK_VISIBLILITY,
  SET_CREDENTIALS,
} from './actions'

const initialSurveyState = {
  email: '',
  subnumber: '',
  postcode: '',
  gender: '',
  age: '',
  whatKindOfMusic: '',
  station: {
    '4ZZZ': false,
    'ZedDigital': false
  },
  listen: {
    'car': false,
    'radio': false,
    'stream': false,
    'phone': false,
    'other': false
  },
  otherListen: '',
  howCouldWeMakeItEasier: '',
  whatWouldYouLikeToHear: '',
  formFeedback: '',
  overwrite: false,
}

export function refine(
  state = { search: '', sort: 'Random' }, action
) {
  switch(action.type) {
    case SEARCH:
      return Object.assign({}, state, {
        search: action.search
      })
    case SORT:
      return Object.assign({}, state, {
        sort: action.sort
      })
    default:
      return state
  }
}

export function order(
  state = false, action
) {
  switch(action.type) {
    case TOGGLE_ORDER:
      return state = !state
    case SET_ORDER:
      return state = action.order
    default:
      return state
  }
}

export function favorites(
  state = [],
  action
) {
  switch(action.type) {
    case FAVORITE:
      return xorWith(state, [action.item], function(a, b) {
        return a.alpha === b.alpha && a.title === b.title;
      })
    case IMPORT_FAVORITES:
      return state = action.favorites
    case UPDATE_FAVORITES:
      return state = action.favorites
    case RESET_STATE:
      return state = []
    default:
      return state
  }
}

export function survey(
  state = initialSurveyState, action
) {
  switch(action.type) {
    case UPDATE_SURVEY:
      return Object.assign({}, state, {
        [action.question]: action.value
      })
    case UPDATE_SURVEY_CHECKBOX:
      return Object.assign({}, state, {
        [action.question]: {...state[action.question], [action.checkbox]: action.value}
      })
    case RESET_STATE:
      return state = initialSurveyState;
    default:
      return state
  }
}

export function form(
  state = { error: {}, success: {}, sending: false }, action
) {
  switch(action.type) {
    case FORM_ERROR:
      return Object.assign({}, state, {
        error: {[action.form]: action.error}
      })
    case FORM_SUCCESS:
      return Object.assign({}, state, {
        success: {[action.form]: action.success}
      })
    case FORM_SENDING:
      return Object.assign({}, state, {
        sending: {[action.form]: action.sending}
      })
    case CLEAR_ERROR:
      return { error: {}, success: {}, sending: false }
    case RESET_STATE:
      return state = { error: {}, success: {}, sending: false }
    default:
      return state
  }
}

export function songlist( state = hot100, action ) {
  switch(action.type) {
    case SORT_SONGLIST:
      return sortHot100(hot100, action.sort, action.search)
    default:
      return state
  }
}

export function bookmark( state = {visible: false}, action ) {
  switch(action.type) {
    case BOOKMARK_VISIBLILITY:
      return { visible: action.visible }
    default:
      return state
  }
}

export function credentials( state = {loggedIn: false, token: ''}, action ) {
  switch(action.type) {
    case SET_CREDENTIALS:
      return { loggedIn: true, token: action.token }
    default:
      return state
  }
}

function sortHot100 (array, sortOrder, search) {
  array = array.filter(function(el) {
    return String(el.title).toLowerCase().indexOf(String(search).toLowerCase()) !== -1 || String(el.alpha).toLowerCase().indexOf(String(search).toLowerCase()) !== -1 ;
  })
  if (sortOrder === 'region') {
    array.map((i) => ( i.region = i.l ? '1' : i.a && !i.l ? '2' : '3' ) )
    let grouped = groupBy(array, 'region')
    return flatMap(grouped)
  }
  if (sortOrder === 'Random') {
    return shuffle(array)
  }
  return array.sort(function(a, b) {


    var nameA = String(a[sortOrder]).toUpperCase(); // ignore upper and lowercase
    var nameB = String(b[sortOrder]).toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  })
}
