import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import { Textfit } from 'react-textfit';

class HotList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      picks: [],
      boxText: '',
      formattedText: '',
      rendered: false
    }
    this.test = this.test.bind(this);
  }

  componentDidMount() {
    let { id } = this.props.match.params
    const canvas = this.refs.canvas
    const ctx = canvas.getContext("2d")
    const img = this.refs.image

    let exampleParagraph = ''
    let text = ''


    // const svgImg = document.querySelector('#test');
    img.onload = () => {
      ctx.drawImage(img, 0, 0)
      fetch('https://hot100.4zzz.org.au/api/hotlist/' + id).then(response => response.json())
      .then(json => {

        // var n = json.length
        // json.forEach ( (i, j) => {
        //   let fx = 24;
        //   if (n > 10) {
        //     fx = 10 / n * fx
        //   }
        //   ctx.font = '700 ' + fx + 'pt Roboto'
        //   ctx.textAlign = 'left'
        //   ctx.fillStyle = j % 2 ? "#ff429e" : '#ff6c00'
        //   ctx.strokeStyle = '#000';
        //   ctx.fillText( i.artist.toUpperCase() + ' - ' + i.title.toUpperCase() , 500, j * (fx * 2) + 380, 800)
        //   // ctx.strokeText( i.artist.toUpperCase() + ' - ' + i.title.toUpperCase() , 500, j * (fx * 2) + 380, 800)
        // })

        var n = json.length
        json.forEach ( (i, j) => {
          let fx = 24;
          if (n > 10) {
            fx = 10 / n * fx
          }
          text += i.artist.toUpperCase() + ' — ' + i.title.toUpperCase() + ' ';
          exampleParagraph += `<span style="color: ${j % 2 ? 'rgb(255, 66, 158)' : 'rgb(211, 2, 12)'};">${i.artist.toUpperCase() + ' — ' + i.title.toUpperCase()}</span> `
          // ctx.strokeText( i.artist.toUpperCase() + ' - ' + i.title.toUpperCase() , 500, j * (fx * 2) + 380, 800)
        })

        this.setState({boxText: text, formattedText: exampleParagraph, picks: json })
     

  

      })
    }




  }

  test(event) {
    const fitBox = this.refs.fitBox
    if (this.state.boxText !== '' && !this.state.rendered ){

      const canvas = this.refs.canvas
      const ctx = canvas.getContext("2d")

      const fontSize = fitBox.state.fontSize;
      console.log('when', fontSize)

      const svgImg = document.createElement('img');


      const words = this.state.boxText.split(" ").length;
      const svgCode = `<svg width="480" height="550" xmlns="http://www.w3.org/2000/svg">
      <foreignObject x="0" y="0" width="480" height="550">
          <p style="font-size: ${fontSize}px; line-height: 100%; font-weight: 700; text-align: justify; text-justify: inter-character; text-transform: uppercase; font-family: Roboto, sans-serif" xmlns="http://www.w3.org/1999/xhtml">${this.state.formattedText}</p>
      </foreignObject>
  </svg>`;
      // Remove newlines and replace double quotes with single quotes
      const svgCodeEncoded = svgCode.replace(/\n/g, '').replace(/"/g, "'");

      svgImg.onload = () => {
        // Get the output canvas context
    
        // Draw the image to the canvas
        ctx.drawImage(svgImg, 270, 340);
        this.setState({'cnvImage': canvas.toDataURL(), rendered: true})

    };
    
      // Dynamically create an image element
     svgImg.src = `data:image/svg+xml,${svgCodeEncoded}`;
      

    }
  }

  render () {
    // let { picks } = this.state
    // let { id } = this.props.match.params
    return (
      <Paper>
        {/*(picks.map((i) => (
          <div><b>{i.artist}</b> - {i.title}</div>  )
        )*/}
        <Typography variant="h6">THANKS FOR YOUR VOTE!</Typography>
        <Textfit ref="fitBox" onReady={() => { this.test() }} class="box" style={{ color: 'transparent', width:'480px', height:'300px', lineHeight: '100%', textAlign: 'justify', textJustify: 'inter-character', textTransform: 'uppercase', fontFamily: 'Roboto, sans-serif', display: this.state.rendered ? 'none' : 'block'}}>
          {this.state.boxText}
        </Textfit>
        <img id='test' />
        <canvas ref="canvas" width={1000} height={1000} style={{width: '100%', display: 'none'}} />
        <img alt="the reference" ref="image" src={ process.env.PUBLIC_URL + '/ivoted2023.png'} style={{visibility: 'hidden', display: 'none'}} />
        <img alt="my-votes" src={this.state.cnvImage} style={{width: '100%'}} />
      </Paper>
    )
  }
}

export default withRouter(HotList)
