import React, { memo, useState }  from 'react'
import { connect } from 'react-redux'
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, areEqual } from 'react-window';
import memoize from 'memoize-one';
import { some } from 'lodash/lodash';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { favoriteWithSave, sortSonglist, setSearch, setSort } from './actions'

const Row = memo(({ data, index, style }) => {
  const { items, favorites, setFavorite, sort } = data;

  let i = items[index];
  return (
    <ListItem key={index} style={style} button onClick={setFavorite(i, index)}>
      <ListItemText primary={
        <React.Fragment>
          {sort === 'title' ?
           <span>{i.title} - <b>{i.alpha}</b></span>
          : sort === 'region' ?
            <span><b>{i.alpha}</b> - {i.title} <span style={{fontStyle: 'italic', fontSize: '0.75em'}}>{i.region === '1' ? 'Local' : i.region === '2' ? 'Australia' : 'International' }</span></span>
          :
            <span><b>{i.artist}</b> - {i.title}</span>
          }
        </React.Fragment>
      }
      />
      <ListItemIcon style={{minWidth: 'auto'}}>
        { some(favorites, i) ?
          <Favorite color="secondary" />
        :
          <FavoriteBorder />
        }
      </ListItemIcon>
    </ListItem>
  );
}, areEqual);

const SongList = (props) => {
  const { favorites, songlist, refine, dispatch } = props

  const setFavorite = (item, i) => event => {
     dispatch(favoriteWithSave(item))
   }

  // const [search, setSearch] = useState('');
  // const [sort, setSort] = useState('Random');
  const [showAdd, setShowAdd] = useState(false);
  const [artist, setArtist] = useState('')
  const [title, setTitle] = useState('')

  const createItemData = memoize((items, favorites, setFavorite, sort) => ({
    items, favorites, setFavorite, sort
  }));

  const itemData = createItemData(songlist, favorites, setFavorite, refine.sort);
  const clearSearch = () => {
    dispatch(sortSonglist(refine.sort, '' ))
    dispatch(setSearch(''))
  }

  const addArtist = () => {
    if ( artist.length > 0 && title.length > 0 ) {
      let hot100artist = { artist: artist, alpha: artist, title: title, userAdded: true }
      // this.sorted.push(hot100artist)
      // hot100.push(hot100artist)
      dispatch(favoriteWithSave(hot100artist))
      setShowAdd(false)
      setArtist('')
      setTitle('')
    }
  }

  return (<Box component="main" style={{flexGrow: 1}} display="flex" flexDirection="column" bgcolor="background.paper">
    <Box p={1}>
      <Grid container spacing={1} style={{padding: 6}}>
        <Grid item xs={9} sm={9} lg={9} xl={9}>
          <TextField
            id="standard-full-width"
            label="Search"
            value={refine.search}
            onChange={(e) => { dispatch(setSearch(e.target.value)); dispatch(sortSonglist(refine.sort, e.target.value )) }}
            placeholder="Song or artist"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                {refine.search.length > 0 ?
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={clearSearch}
                  >
                     <Clear />
                  </IconButton>
                : <React.Fragment></React.Fragment> }
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3} sm={3} lg={3} xl={3}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="age-native-simple">Sort Order</InputLabel>
            <Select
              value={refine.sort}
              onChange={(e) => { dispatch(setSort(e.target.value)); dispatch(sortSonglist( e.target.value, refine.search)) }}
              inputProps={{
                name: 'sort',
                id: 'sort-list',
              }}
            >
              <MenuItem value={'Random'}>Random</MenuItem>
              <MenuItem value={'alpha'}>Artist</MenuItem>
              <MenuItem value={'title'}>Song</MenuItem>
              <MenuItem value={'region'}>Region</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
    <Box flexGrow={1}>
      <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList height={height} width={width} itemSize={48} itemData={itemData} itemCount={songlist.length}>
          {Row}
        </FixedSizeList>
      )}
      </AutoSizer>
    </Box>
    <Box p={1}>
    <Grid container spacing={1} style={{padding: 6}}>
      <Grid item xs={12}>
        <Box display="flex">
          <Box flexGrow={1}>
            { showAdd &&
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={6} xl={6}>
                <TextField
                  id="add-artist"
                  label="artist"
                  value={artist}
                  onChange={(e) => setArtist(e.target.value)}
                  placeholder="Artist Name"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} xl={6}>
                <TextField
                  id="add-title"
                  label="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Song Title"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            }
          </Box>
          <Box>
          { showAdd ?
          <Tooltip placement="left" title="Add" aria-label="add">
            <Fab onClick={addArtist} size="small" color="primary" style={{ marginTop: 24 }}>
              <Favorite />
            </Fab>
          </Tooltip>
          : <Tooltip title="Add a song not in list" placement="left" aria-label="add">
            <Fab color="secondary" size="small" onClick={() => setShowAdd(true) }>
              <AddIcon />
            </Fab>
            </Tooltip>
          }
          </Box>
        </Box>

      </Grid>
    </Grid>
    </Box>
  </Box>)
}

export default connect( state => ({ songlist: state.songlist, favorites: state.favorites, order: state.order, refine: state.refine })) (SongList)
