import React, { useState }  from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/styles';
import { some } from 'lodash/lodash';
import DragHandle from '@material-ui/icons/DragIndicator';
import Delete from '@material-ui/icons/Delete';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import SendIcon from '@material-ui/icons/Send';
import FormDialog from './FormDialog'
import BookmarkDialog from './BookmarkDialog'
import { updateFavoritesWithSave, toggleOrder, favoriteWithSave, setBookmarkVisible } from './actions'

const getItemStyle = (isDragging, draggableStyle, theme) => {
  return ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? theme.palette.secondary[600] : "inherit",

    // styles we need to apply on draggables
    ...draggableStyle
  })
};


const FavoriteRow = props => {
  const theme = useTheme();

  const { item, favorites, setFavorite, index, number, order } = props;

  let i = item;
  return (
    <Draggable key={index} draggableId={'draggable-' + index} index={index}>
    {(provided, snapshot) => (
    <div ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(
        snapshot.isDragging,
        provided.draggableProps.style, theme
      )}
      >
      <ListItem disableGutters key={index}>
        <ListItemIcon>
          <React.Fragment>
            <DragHandle style={{ fontSize: 16 }} />
            { order && number && <i style={{ fontSize: 12}}>{index + 1}</i> }
          </React.Fragment>
        </ListItemIcon>
        <ListItemText primary={
          <React.Fragment>
            <b>{i.alpha}</b> - {i.title}
          </React.Fragment>
          }
        />
        <ListItemIcon style={{minWidth: 'auto'}}>
          <Icon edge="end" aria-label="delete">
            { some(favorites, i) ?
            <Delete onClick={setFavorite(i, index)}/>
            :
            <FavoriteBorder />
            }
          </Icon>
        </ListItemIcon>
      </ListItem>
    </div>
    )}
    </Draggable>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Shortlist = (props) => {
  const { favorites, dispatch, order, bookmark } = props
  // const [ bookmarkVisible, setBookmarkVisible] = useState(false);
  const [ submitVisible, setSubmitVisible ] = useState(false)
  const [ selectedValue, setSelectedValue ] = useState('')
  const [ number, setNumber ] = useState(true)

  const handleClose = value => {
    // if (value === 'save') {
    //   localStorage.setItem("favorites", JSON.stringify(this.state.favorites) );
    // }
    // if (value === 'restore') {
    //   if(localStorage.length > 0) {
    //     let favorites = JSON.parse(localStorage.getItem("favorites"));
    //     if (!favorites) {
    //       favorites = []
    //     }
    //     this.setState({favorites: favorites});
    //     this.props.favCount(favorites.length)
    //   }
    // }

    dispatch(setBookmarkVisible(false))
    setSelectedValue(value)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newFavorites = reorder(
      favorites,
      result.source.index,
      result.destination.index
    )

    dispatch(updateFavoritesWithSave(newFavorites))
    setNumber(true)

  }

  const onBeforeDragStart = (tester) => {
    setNumber(false)
  }

  const setFavorite = (item, i) => event => {
     dispatch(favoriteWithSave(item))
   }
  return (
    < >
    <Box component="main" style={{flexGrow: 1, overflow: 'auto'}} display="flex" flexDirection="column" p={1} bgcolor="background.paper">
    { favorites.length > 0 ?
      <Box p={1} flexGrow={1}>
        <Grid item xs={12}>
          <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
            <Droppable droppableId="droppable-1" type="PLAYLIST">
              {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                <List>
                  { favorites.map( (i,k) => (
                    <FavoriteRow key={k} item={i} index={k} setFavorite={setFavorite} number={number} order={order} favorites={favorites}  />
                  ))}
                </List>
                {provided.placeholder}
              </div>
              )}
            </Droppable>
          </DragDropContext>

        </Grid>
      </Box>
      :
      <Box alignContent="center" alignItems="center" alignSelf="center" justifyContent="center" style={{display: 'flex', flexGrow: 1}}>
        <Typography variant="h5">Nothing here yet... <Favorite style={{marginBottom: -5}} /> some titles to get started.</Typography>
      </Box>
     }
     </Box>
      <Box component="footer" p={1} bgcolor="background.paper">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box display="flex">
              <Box flexGrow={1}>
                {favorites.length > 0 && <FormControlLabel style={{padding: '5px'}} control={<Switch color="default" checked={order} onChange={() => dispatch(toggleOrder()) }/>} label={order ? 'In order' : 'In no particular order'} />}
              </Box>
              <Box>
                <Tooltip title="Save/Restore Shortlist" aria-label="save/restore">
                  <IconButton onClick={() => dispatch(setBookmarkVisible(true)) }>
                    <BookmarkIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip  title="Submit HOT100!" aria-label="send">
                  <span>
                    <IconButton color="primary" disabled={favorites.length === 0} onClick={() => setSubmitVisible(true) }>
                      <SendIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <FormDialog
       selectedValue={selectedValue} open={submitVisible} onClose={() => setSubmitVisible(false) } />
      <BookmarkDialog selectedValue={selectedValue} open={bookmark.visible} onClose={handleClose} favCount={favorites.length} />
    < />
  )
}

export default  connect( state => ({ songlist: state.songlist, favorites: state.favorites, order: state.order, refine: state.refine, bookmark: state.bookmark }))  (Shortlist)
