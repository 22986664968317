import React from 'react'
import { connect } from 'react-redux'
import { updateSurvey, updateSurveyCheckbox, saveRestore } from './actions'
import { useTheme } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';

import Clear from '@material-ui/icons/Clear';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircle from '@material-ui/icons/CheckCircle';

const BookmarkDialog = (props, dispatch) => {
  const { onClose, selectedValue, open, survey, favCount, form } = props;
  const theme = useTheme();

  const surveyHandler = (question, checkbox) => event => {
    if (question === 'listen' || question === 'station' ) {
      props.dispatch(updateSurveyCheckbox(question, checkbox, event.target.checked ))
    }
    else {
      props.dispatch(updateSurvey(question, event.target.value ))
    }
  }

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  const userForm = event => {
    event.preventDefault();
    props.dispatch(saveRestore())
  }

  return (
    <Dialog transitionDuration={{enter: 100, exit: 1000}} PaperProps={{className: form.error.saveRestore ? 'headShake' : ''  } } onClose={handleClose} open={open}>
      <div className={form.success.saveRestore ? 'visible' : 'hidden'} style={{ zIndex: 1, position: 'absolute', top: 'calc(50% - 6rem)', left: 'calc(50% - 6rem)'}}><CheckCircle style={{fontSize: '12rem' }} /></div>
      <form className={ form.success.saveRestore ? 'hidden' : 'visible' } onSubmit={userForm}>
      <DialogTitle>Subscriber save feature</DialogTitle>
      <DialogContent>
          <DialogContentText>
          4ZZZ Subscribers can save their shortlist and come back to it later by entering their subscriber number and matching email. To come back to your shortlist after saving, return to this page and enter your details to Restore your songs and keep whittling down your shortlist. <br />Not a subscriber?&nbsp;
          <a href="//4zzz.org.au/subscribe" target="_blank" rel="noopener noreferrer">Fix that now.</a>
          </DialogContentText>
          <div>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={survey.email}
              onChange={surveyHandler('email')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="subnumber"
              label="Sub number"
              id="password"
              autoComplete="subnumber"
              value={survey.subnumber}
              onChange={surveyHandler('subnumber')}
            />
            {/*<Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Sign In
            </Button>*/}
        </div>
      </DialogContent>
      <DialogActions>
        { favCount > 0 ? <Tab style={{ color: theme.palette.secondary[200]}} onClick={userForm} color="primary" label="Save" icon={<CloudUploadIcon />} aria-label="person" /> : <Tab style={{ color: theme.palette.secondary[200]}} type="submit" onClick={userForm} color="primary" label="Restore" icon={<CloudDownloadIcon />} aria-label="person" /> }
        {/* <Tab style={{ color: theme.palette.secondary[200]}} onClick={() => handleListItemClick('save')} color="primary" label="Save" icon={<CloudUploadIcon />} aria-label="person" />
        <Tab  style={{ color: theme.palette.secondary[200]}} onClick={() => handleListItemClick('restore')} color="primary" label="Restore" icon={<CloudDownloadIcon />} aria-label="person" /> */}
        <Tab style={{ color: theme.palette.primary[200]}} onClick={() => handleListItemClick('cancel')} color="primary" label="Cancel" icon={<Clear />} aria-label="person" />
      </DialogActions>
      </form>
    </Dialog>
  )
}

export default connect(state => ({ form: state.form, survey: state.survey, favorites: state.favorites }) )  (BookmarkDialog)
