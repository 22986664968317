import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { updateSurvey, updateSurveyCheckbox, saveSurvey, clearError } from './actions'
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Form = (props) => {
  const { survey, handleClose, form } = props;
  const classes = useStyles();
  const surveyHandler = (question, checkbox) => event => {

    if ((question === 'email' || question === 'subnumber') && form.error.survey && form.error.survey === 409 ) {
      props.dispatch(clearError())
    }
    if (question === 'listen' || question === 'station' ) {
      props.dispatch(updateSurveyCheckbox(question, checkbox, event.target.checked ))
    }
    else if (question === 'overwrite') {
      props.dispatch(updateSurvey(question, event.target.checked ))
    }
    else {
      props.dispatch(updateSurvey(question, event.target.value ))
    }
  }
  const sendSurvey = event => {
    event.preventDefault();
    props.dispatch(saveSurvey(props.history))
  }
  return(
    <React.Fragment>
    <AppBar position="relative" elevation={0} className={classes.appBar}>
      <Toolbar>
        <Typography style={{flexGrow: 1}} variant="h4">Almost there...</Typography>

        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Box className={classes.paper}>
      <Typography variant="body1">We would love to get your thoughts on a few things about 4ZZZ before you submit your Hot 100 vote. If you'd rather not, keep clicking through to submit your tracks for the Hot 100 2020!</Typography>
      <form onSubmit={sendSurvey}>
        <TextField
          error={form.error.survey ? true : false }
          helperText={form.error.survey === 409 ? 'This email ' + ( survey.subnumber ? ' and subnumber combination ' : '' ) + ' has been used previously.' : null }
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={survey.email}
          onChange={surveyHandler('email')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="subnumber"
          label="Sub number"
          id="password"
          autoComplete="subnumber"
          value={survey.subnumber}
          onChange={surveyHandler('subnumber')}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Post Code"
          name="postcode"
          autoComplete="postcode"
          value={survey.postcode}
          onChange={surveyHandler('postcode')}
        />

        <FormControl margin="normal" variant="outlined" fullWidth >
          <InputLabel id="gender-label">
            Gender
          </InputLabel>

          <Select
            id="gender"
            value={survey.gender}
            onChange={surveyHandler('gender')}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="woman">Woman</MenuItem>
            <MenuItem value="man">Man</MenuItem>
            <MenuItem value="non-binary">Non Binary</MenuItem>
            <MenuItem value="not-listed">Not Listed</MenuItem>
          </Select>
        </FormControl>

        <FormControl margin="normal" variant="outlined" fullWidth>
          <InputLabel id="age-label">
            Age
          </InputLabel>

          <Select
            id="age"
            onChange={surveyHandler('age')}
            value={survey.age}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="15-">15 and under</MenuItem>
            <MenuItem value="16-20">16-20</MenuItem>
            <MenuItem value="21-25">21-25</MenuItem>
            <MenuItem value="26-30">26-30</MenuItem>
            <MenuItem value="31-35">31-35</MenuItem>
            <MenuItem value="36-40">36-40</MenuItem>
            <MenuItem value="41-45">41-45</MenuItem>
            <MenuItem value="46-50">46-50</MenuItem>
            <MenuItem value="51-55">51-55</MenuItem>
            <MenuItem value="56-60">56-60</MenuItem>
            <MenuItem value="61+">61 and above</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="what-kind-of-music"
          label="What kind of music do you love hearing on 4ZZZ?"
          multiline
          fullWidth
          rows="4"
          variant="outlined"
          margin="normal"
          value={survey.whatKindOfMusic}
          onChange={surveyHandler('whatKindOfMusic')}
        />

        <FormControl fullWidth margin="normal" component="fieldset">
          <FormLabel component="legend">What 4ZZZ channel do you listen to most?</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={survey.station['4ZZZ']} onChange={surveyHandler('station', '4ZZZ')} value="station.4ZZZ" />}
              label="102.1FM"
            />
            <FormControlLabel
              control={<Checkbox checked={survey.station['ZedDigital']} onChange={surveyHandler('station', 'ZedDigital')} value="station.ZedDigital" />}
              label="Zed Digital"
            />
          </FormGroup>
        </FormControl>

        <FormControl fullWidth margin="normal" component="fieldset">
          <FormLabel component="legend"> What is the most common way you listen to 4ZZZ?</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={survey.listen['car']} onChange={surveyHandler('listen', 'car')} value="car" />}
              label="In my car"
            />
            <FormControlLabel
              control={<Checkbox checked={survey.listen['radio']} onChange={surveyHandler('listen', 'radio')} value="radio" />}
              label="On a radio"
            />
            <FormControlLabel
              control={<Checkbox checked={survey.listen['stream']} onChange={surveyHandler('listen', 'stream')} value="stream" />}
              label="Stream online"
            />
            <FormControlLabel
              control={<Checkbox checked={survey.listen['phone']} onChange={surveyHandler('listen', 'phone')} value="phone" />}
              label="Online on my phone"
            />
            <FormControlLabel
              control={<Checkbox checked={survey.listen['other']} onChange={surveyHandler('listen', 'other')} value="other" />}
              label="Other"
            />
          </FormGroup>
        </FormControl>

        { survey.listen.other && <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="otherListen"
          label="How do you listen to 4ZZZ"
          name="otherListen"
          autoComplete="otherListen"
          autoFocus
          value={survey.otherListen}
          onChange={surveyHandler('otherListen')}
        />
        }
        <TextField
          id="how-could-we-make-it-easier"
          label="How could we make it easier for you to listen to 4ZZZ programs and segments?"
          multiline
          fullWidth
          rows="4"
          variant="outlined"
          margin="normal"
          value={survey.howCouldWeMakeItEasier}
          onChange={surveyHandler('howCouldWeMakeItEasier')}
        />

        <TextField
          id="what-would-you-like-to-hear"
          label="What would you like to hear more of on 4ZZZ?"
          multiline
          fullWidth
          rows="4"
          variant="outlined"
          margin="normal"
          value={survey.whatWouldYouLikeToHear}
          onChange={surveyHandler('whatWouldYouLikeToHear')}
        />

        <TextField
          id="form-feedback"
          label="Do you have any feedback about our Hot 100 voting form?"
          multiline
          fullWidth
          rows="4"
          variant="outlined"
          margin="normal"
          value={survey.formFeedback}
          onChange={surveyHandler('formFeedback')}
        />

        {form.error.survey === 409 &&
        <Box bgcolor="error.main" color="error.contrastText" p={2} m={1}>
          <Typography>
          This email and subnumber combination has been used to submit a vote previously. Either enter a different email or subscriber number or tick the checkbox below to override a previous vote.
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={survey.overwrite} onChange={surveyHandler('overwrite')} value="overwrite" />}
            label="Override previous vote"
          />
        </Box>
        }

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          disabled={form.sending.survey}
          // onClick={sendSurvey}
        >
          Submit your songs!
        </Button>
      </form>

    </Box>
    </React.Fragment>
  )}


export default withRouter( connect(state => ({ survey: state.survey, favorites: state.favorites, form: state.form }) ) (Form) )
